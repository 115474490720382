import { useNavigate } from "react-router-dom";
import logo from "../assets/Climax.svg";

const Footer = () => {
  const navigate = useNavigate();

  const nav = [
    { name: "Home", link: "/" },
    { name: "About", link: "#about" },
    { name: "Modules", link: "/modules" },
    { name: "Xtreme", link: "/xtreme" },
  ];

  const modules = [
    { name: "Spark", link: "#spark" },
    { name: "Edge", link: "#edge" },
    { name: "Zenith", link: "#zenith" },
    { name: "Customized", link: "#custom" },
  ];

  const handleNavigation = (link: string) => {
    if (link.startsWith("#")) {
      // Navigate to Home ("/") and scroll to the specific section
      navigate("/modules", { replace: true });
      setTimeout(() => {
        const section = document.getElementById(link.substring(1)); // Remove '#' to get the ID
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 50);
    } else {
      // Navigate to other routes
      navigate(link);
    }
  };

  const handleAboutNav = (link: string) => {
    if (link.startsWith("#")) {
      // Navigate to Home ("/") and scroll to the specific section
      navigate("/", { replace: true });
      setTimeout(() => {
        const section = document.getElementById(link.substring(1)); // Remove '#' to get the ID
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 50);
    } else {
      // Navigate to other routes
      navigate(link);
    }
  };

  return (
    <footer className="bg-slate-200 pt-10 pb-5">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-2/6 mb-8 md:mb-0">
            <div className="flex gap-3">
              <img
                src={logo}
                className="size-14 bg-white rounded-full"
                alt="Climax Logo"
              />
              <h3 className="text-xl font-heading font-bold mb-4 mt-[2px]">
                The Climax:{" "}
                <span className="block text-sm">
                  Concluding with new beginning
                </span>
              </h3>
            </div>
            <p className="text-[12px] font-body px-1 mt-1">
              Join us as we embark on a journey of growth, leadership, and
              transformation. At Climax, your success is our mission. Together,
              we’ll create a future where communication and leadership redefine
              the way you shine.
            </p>
          </div>
          <div className="w-1/2 md:w-1/6 mb-8 md:mb-0">
            <h3 className="text-xl font-heading font-bold mb-4">Quick Links</h3>
            <ul className="font-body space-y-2">
              {nav.map((item) => (
                <li
                  key={item.name}
                  className="cursor-pointer"
                  onClick={() => handleAboutNav(item.link)}
                >
                  <span className="hover:underline">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-1/2 md:w-1/6 mb-8 md:mb-0">
            <h3 className="text-xl font-heading font-bold mb-4">Modules</h3>
            <ul className="font-body space-y-2">
              {modules.map((module) => (
                <li
                  key={module.name}
                  className="cursor-pointer"
                  onClick={() => handleNavigation(module.link)}
                >
                  <span className="hover:underline">{module.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="text-center mt-8">
          <p className="text-sm font-body">
            &copy; {new Date().getFullYear()} The Climax. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
