import React, { useState, useEffect, useRef } from "react";
import { FaRegHandPointRight } from "react-icons/fa";

// Define your modules data
const modules = [
  {
    id: "spark",
    title: "Module 1: Spark",
    description:
      "Your journey begins with Spark—a foundational module that helps you express yourself effectively in every format: writing, speaking, and listening. With Spark, you will master the ability to articulate ideas clearly, concisely, and confidently in any situation.",
    keyFocusAreas: [
      "Effective communication strategies",
      "Mastery of writing, speaking, and listening",
      "Building confidence in self-expression",
    ],
    image: "https://i.ibb.co/jzYY5ZK/spark.jpg",
  },
  {
    id: "edge",
    title: "Module 2: Edge",
    description:
      "For those seeking to sharpen their presence and master the art of communication, Edge takes you to the next level. This module specializes in enhancing your accent, fine-tuning body language, and making you stage-ready for presentations and public speaking. Become an expert in communication and captivate any audience.",
    keyFocusAreas: [
      "Accent training and refinement",
      "Mastery of body language",
      "Public speaking and presentation skills",
    ],
    image: "https://i.ibb.co/Z2v2bpq/edge.jpg",
  },
  {
    id: "zenith",
    title: "Module 3: Zenith",
    description:
      "Our exclusive Zenith module is designed for top-tier professionals—C-level executives, heads of institutions, and individuals aiming to lead organizations. This one-on-one training program equips you with advanced management skills and leadership presence, giving you the tools to take full responsibility and drive an organization to new heights.",
    keyFocusAreas: [
      "Strategic leadership and decision-making",
      "Executive communication and negotiation skills",
      "Crisis management and conflict resolution",
      "Visionary thinking and innovative leadership",
    ],
    image: "https://i.ibb.co/9pSG4CS/zenith.jpg",
  },
  {
    id: "custom",
    title: "Customized Programs",
    description:
      "In addition to our standard modules, Climax offers bespoke training programs to meet the specific needs of individuals and organizations. Whether it's crafting a flawless sales pitch or mastering a unique accent, we provide tailor-made solutions to help you excel in any situation.",
    keyFocusAreas: [
      "Sales Pitch Mastery",
      "Masterclasses on Accents",
      "Custom Communication Coaching",
    ],
    image: "https://i.ibb.co/RHZ0rZM/customized-programs.jpg",
  },
];

const Module = () => {
  const [circlePos, setCirclePos] = useState({ x: 0, y: 0 });
  const mousePos = useRef({ x: 0, y: 0 }); // Use a ref for mouse position to avoid re-renders

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      mousePos.current = { x: e.clientX, y: e.clientY }; // Update mouse position
    };

    const updateCirclePosition = () => {
      // Gradually update the circle position to move slightly towards the mouse position
      setCirclePos((prevPos) => {
        const newX = prevPos.x + (mousePos.current.x - prevPos.x) * 0.2; // Use 0.1 to create slight movement
        const newY = prevPos.y + (mousePos.current.y - prevPos.y) * 0.2;
        return { x: newX, y: newY };
      });

      requestAnimationFrame(updateCirclePosition); // Keep updating
    };

    window.addEventListener("mousemove", handleMouseMove);
    requestAnimationFrame(updateCirclePosition); // Start the animation loop
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <section
      id="modules"
      className="py-10 overflow-x-hidden md:mt-5 lg:mt-10 theam-grad"
    >
      <div className="container">
        <div className="border-l-4 border-tertary px-5 mb-4">
          <h4 className="font-body text-[10px]">
            Unlock the Power of Communication and Leadership
          </h4>
          <h1 className="text-5xl text-secondary font-heading font-medium">
            Our Modules
          </h1>
        </div>
        {modules.map((module, i) => (
          <div
            key={module.id}
            id={module.id}
            className={`flex flex-col items-center pt-24 pb-0 relative module transition-all duration-500 ease-in-out ${
              i % 2 !== 0
                ? "md:flex-row-reverse md:after:left-[46%] md:after:top-0"
                : "md:flex-row md:after:-left-10 md:after:top-0"
            }`}
            style={
              {
                "--circle-x": `${circlePos.x}px`,
                "--circle-y": `${circlePos.y}px`,
              } as React.CSSProperties
            }
          >
            <div className="w-full md:w-1/2 space-y-5">
              <div className="space-y-4">
                <h2 className="text-4xl text-secondary font-heading">
                  {module.title}
                </h2>
                <p className="font-body font-normal text-sm md:text-base lg:text-lg text-balance leading-[22px]">
                  {module.description}
                </p>
              </div>
              <ul className="font-body font-normal text-[12px] xs:text-sm md:text-base lg:text-lg text-balance leading-[22px] space-y-2">
                <h4 className="font-medium font-heading text-2xl mb-2">
                  Key Focus Areas:
                </h4>
                {module.keyFocusAreas.map((focusArea, i) => (
                  <li key={i}>
                    <FaRegHandPointRight className="inline mr-2" />
                    {focusArea}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/2 px-2 mt-7 md:mt-0">
              <img
                src={module.image}
                className="size-full md:size-[85%] rounded-xl noselect"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Module;
